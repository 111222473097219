import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {formURL, getuserId} from '../helpers/utils'
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class FormService{
    constructor(private http: HttpClient) { }

    private appData = new BehaviorSubject({});
    getAppData = this.appData.asObservable();

    setAppData(data: any) {
      this.appData.next({...data});
    }
    
  public verifyOTP(formId: string, otp?: number):  Observable<any> {
    let data = { 
      "client": environment.APP_CLIENTID,
      "answers": [{"a_otp": otp}]
    };
    return this.http.post(formURL("verifyOTP",formId), JSON.stringify(data)
      ).pipe(
        map((res: any) => {
          return res || {}
        }),
        catchError(this.errorHandling)
      )
}

    public getFormData(formId: string, clientId?: string, postCode?: number):  Observable<any> {
        let params;
        if (clientId && postCode) {
            params = {
                "client": clientId,
                "a_postcode": postCode
            }
        }
        return this.http.get(formURL("getData",formId),{ params}
          ).pipe(
            map((res: any) => {
              return res || {}
            }),
            catchError(this.errorHandling)
          )
    }

    public getFormStatus(formId: string):  Observable<any> {
      
      return this.http.get(formURL("getStatus",formId)
        ).pipe(
          map((res: any) => {
            return res || {}
          }),
          catchError(this.errorHandling)
        )
  }
    public checkSession(formId: string):  Observable<any> {
      return this.http.get(formURL("checkSession",formId)
        ).pipe(
          map((res: any) => {
            return res || {}
          }),
          catchError(this.errorHandling)
        )
  }

  public endSession(formId: string):  Observable<any> {
    return this.http.delete(formURL("endSession",formId)
      ).pipe(
        map((res: any) => {
          return res || {}
        }),
        catchError(this.errorHandling)
      )
}

    
    public saveFormData(formId: string, data: any):  Observable<any> {
      let params;
      return this.http.post(formURL("postData",formId),JSON.stringify(data)
        ).pipe(
          map((res: any) => {
            return res || {}
          }),
          catchError(this.errorHandling)
        )
  }

  public requestOTP(formId: string):  Observable<any> {
    let data = {
      "client": environment.APP_CLIENTID,
      "originatingApp": environment.APP_ORIGINATINGAPP,
      "templateId": environment.APP_TEMPLATEID,
      "clientData": ""
  };
    return this.http.post(formURL("reqOTP",formId),JSON.stringify(data)
      ).pipe(
        map((res: any) => {
          return res || {}
        }),
        catchError(this.errorHandling)
      )
}


    errorHandling(error: any) {
      console.log('error', error);
        let errorMessage = error?.error?.code;
        console.log(errorMessage);
        return throwError(error.error);
      }
}