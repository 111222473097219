<div class="row">
        <div class="col-sm-12">
            <h1 tabindex="0" #thankYouHeading class="mb-3 mt-3">Thank you,</h1>
            <div *ngIf="showError" class="alert alert-warning mt-3" role="alert" id="error-warning">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" viewBox="0 0 16 16">
                    <path
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  {{error_message}}
                </p>
              </div>
            <div tabindex="0" class="alert alert-inverted">
                <p class="h4">Saved!</p>
            </div>
            <div tabindex="0" class="alert alert-secondary">
                Thank you {{applicationData.or_first_name}}, your updated preferences have been saved. You’ll continue to receive paper statements.
            </div>
            <p tabindex="0">If you have any questions about this process, please contact Customer Service on <a
                    href='tel:{{contactNumber}}'>{{contactNumber}}</a>
                {{contactTimes}}
            </p>
        </div>
 </div>
