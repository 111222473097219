import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../service/form.service';
import { environment } from '../../environments/environment';
import { renderError, setuserId, removeuserId,setPostcode,removePostcode } from '../helpers/utils';
import { gEvent, gUser, reporting, startGoogle } from '../helpers/reporting';
import { HomeComponent } from '../home/home.component';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent implements OnInit {

  formId!: string;
  error_message!: string;
  error: any =  {};
  showError: boolean =false;
  formData: any;
  disableContinueBtn: boolean = false;
  OTPIsSending: boolean = false;
 
  @ViewChild('mainHeading', {static: false}) mainHeader!: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef, private el: ElementRef, private homeComp: HomeComponent, private route: ActivatedRoute, private formService:FormService, private router: Router) {

  }

  
  ngOnInit(): void {
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
    

    removeuserId('USER_ID');
    reporting('/');
    startGoogle();
    this.showError = false;
    this.formId = this.route.snapshot.params['id'];
    gUser(this.formId);
    setuserId(this.formId)
    this.formService.getFormStatus(this.formId).subscribe(res=> {
      console.log(`Form status is ${res.status}`);
      switch (res.status) {
        case "COMPLETED":
            console.log(`switch - completed`)
            this.router.navigate(['/completed']);
            break;
        case "DISABLED":
            console.log(`switch - disabled`)
            this.router.navigate(['/offerexpired']);
            break;
        default:
           this.formService.getFormData(this.formId).subscribe(data => {
            this.formData = {...data};
            this.changeDetectorRef.detectChanges();
            this.formService.setAppData(data);
           // this.mainHeader.nativeElement?.focus();
          },error=>{
            this.showError = true;
            console.log('error', error);
            this.error = renderError(error?.code);
            this.error_message = this.error?.error_message;
          });
        }
    },error=>{
      this.showError = true;
      console.log('error', error);
      this.error = renderError(error?.code);
      this.error_message = this.error?.error_message;
    })
  }

  requestOTP() {
    this.OTPIsSending = true;
    this.disableContinueBtn = true;
    this.formService.requestOTP(this.formId).subscribe(data=> {
      gEvent("OTP requested success", "Verification", "OTP requested successfully")
      this.OTPIsSending = false;
      console.log('OTP sent', data);
      this.router.navigate(['/otp']);
    },error=>{
      gEvent("OTP request failed", "Verification", "OTP request failed")
      this.showError = true;
      console.log('error', error);
      this.error = renderError(error?.code);
      this.error_message = this.error?.error_message;
    });
  }
}
