
import { environment } from '../../environments/environment';
import axios from 'axios'
import { gEvent } from './reporting';

export const BASEURL = "/forms"
export const CLIENTID = environment.APP_CLIENTID
export const ORIGINATINGAPP = environment.APP_ORIGINATINGAPP

// Build URLs to endpoints for common functions
export function formURL(urlType: string, userID?: string, docType?: string,): string {

    let BASE = BASEURL
    let USER_ID = userID
    let DOC_TYPE = docType

    const urls:  { [char: string]: string } = {
        getData: `${BASE}/${USER_ID}?client=${CLIENTID}`,
        postData: `${BASE}/${USER_ID}`,
        getDocument: `${BASE}/${USER_ID}/documents/${docType}?client=${CLIENTID}`,
        verifyPostcode: `${BASE}/${USER_ID}?client=${CLIENTID}`,
        verifyOTP: `${BASE}/${USER_ID}/validate`,
        checkSession: `${BASE}/${USER_ID}/checkSession?client=${CLIENTID}`,
        endSession: `${BASE}/${USER_ID}/endSession?client=${CLIENTID}`,
        getStatus: `${BASE}/${USER_ID}/status?client=${CLIENTID}`,
        reqOTP: `${BASE}/${USER_ID}/otp`,
    }
  
    return urls[urlType];
}


export function isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
}

export function isSI() {
    var ua = navigator.userAgent;
    /* Detect Samsung browser */
    var is_si = navigator.userAgent.match(/SamsungBrowser/i)
    return is_si;
}

/**
 * Sets storage up with the value of the current USER_ID
 * 
 * @param {string} id USER_ID (User ID) from the initial URL
 */
export function setuserId(USER_ID: string) {
    if (USER_ID == '') {
        return console.log(`User ID was not set, was supplied empty`)
    }
    sessionStorage.setItem('USER_ID', USER_ID)
}

/**
 * Removes storage of the value of the current USER_ID
 * 
 * @param {string} id USER_ID (User ID) from the initial URL
 */
export function removeuserId(USER_ID: string) {
    if (USER_ID == '') {
        return console.log(`User ID was not reset, was supplied empty`)
    }
    sessionStorage.removeItem('USER_ID');
}

/**
 * Returns the current USER_ID stored in the cookie
 * 
 */
export function getuserId() : any {
    let USER_ID = sessionStorage.getItem('USER_ID')
    if (USER_ID != '') { return USER_ID }
    else {
        console.log(`User ID was not found, or was empty`)
    }
}

/**
 * Sets storage up with the value of the current postcode
 * 
 * @param {number} id postcode (postcode) from the initial URL
 */
export function setPostcode(postcode: any) {
    if (!postcode) {
        return console.log(`User ID was not set, was supplied empty`)
    }
    sessionStorage.setItem('POST_CODE', postcode)
}

/**
 * Removes storage of the value of the current postcode
 * 
 * @param {string} id postcode (postcode) from the initial URL
 */
export function removePostcode(postcode: string) {
    if (postcode == '') {
        return console.log(`User ID was not reset, was supplied empty`)
    }
    sessionStorage.removeItem('POST_CODE');
}

/**
 * Returns the current postcode stored in the cookie
 * 
 */
export function getPostcode() : any {
    let POST_CODE = sessionStorage.getItem('POST_CODE')
    if (POST_CODE != '') { return POST_CODE }
    else {
        console.log(`POST_CODE was not found, or was empty`)
    }
}

export function renderError(errorStatus: string) : any {

    let errorContent: {error_type: string,error_message: string,error_details: string};
    switch (errorStatus) {
        case 'BAD_REQUEST':
            errorContent = { "error_type": "Missing or invalid values", "error_message": environment.APP_MESSAGE_BAD_REQUEST, "error_details": errorStatus }
            break;
        case 'FORM_EXPIRED':
            errorContent = { "error_type": "Link no longer valid", "error_message": environment.APP_MESSAGE_FORM_EXPIRED, "error_details": errorStatus }
            break;
        case 'NOT_FOUND':
            errorContent = { "error_type": "Record not found", "error_message": environment.APP_MESSAGE_NOT_FOUND, "error_details": errorStatus }
            break;
        case 'FAILED':
            errorContent = { "error_type": "Verification failed", "error_message": environment.APP_MESSAGE_FAILED, "error_details": errorStatus }
            break;
        case 'EXPIRED_CODE':
            errorContent = { "error_type": "Passcode has expired", "error_message": environment.APP_MESSAGE_EXPIRED_CODE, "error_details": errorStatus }
            break;
        case 'FORM_LOCKUP':
            errorContent = { "error_type": "Too many failed attempts", "error_message": environment.APP_MESSAGE_FORM_LOCKUP, "error_details": errorStatus }
            break;
        case 'NOT_ALLOWED':
        case 'UNAUTHORIZED':
            errorContent = { "error_type": "Unauthorised attempt", "error_message": environment.APP_MESSAGE_FORM_UNAUTHORIZED, "error_details": errorStatus }
            break;
        case 'FORBIDDEN':
        case 'SERVICE_UNAVAILABLE':
            errorContent = { "error_type": "Item unavailable", "error_message": environment.APP_MESSAGE_FORBIDDEN, "error_details": errorStatus }
            break;
        case 'NO_ID':
            errorContent = { "error_type": "Invalid link", "error_message": environment.APP_MESSAGE_NO_ID, "error_details": "No ID supplied" }
            break;
        case 'SESSION_DOES_NOT_EXIST':
        case 'COOKIE_EXPIRED':
            errorContent = { "error_type": "Session expired", "error_message": environment.APP_MESSAGE_COOKIE_EXPIRED, "error_details": "Session expired" }
            break;
        case 'PAGE_NOT_FOUND':
            errorContent = { "error_type": "Page not found", "error_message": environment.APP_MESSAGE_NOT_FOUND, "error_details": "Page not found" }
            break;
        default:
            errorContent = { "error_type": "Unknown error occurred", "error_message": environment.APP_MESSAGE_DEFAULT, "error_details": errorStatus }
    }
    gEvent(errorStatus, "General errors", errorContent.error_type);
    return errorContent;
}
