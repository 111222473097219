import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import {isIE, renderError} from '../helpers/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {
  error_message!: string;
  showIEErrorMsg: boolean =  false;
  hardstop:  boolean = false;
  showErrorMsg: boolean = false;
  error: any;
  error_details!: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    
  }
 
  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnInit(): void {
    if (isIE()) {
      console.log('Internet Explorer detected'); // Show errror message
      this.showIEErrorMsg = true;
      this.hardstop = true;
  } 
  // else if(!navigator.cookieEnabled) {
  //   this.error_message = environment.APP_MESSAGE_COOKIE_DISABLED;
  //   this.hardstop = true;
  // }
  else {
    this.showErrorMsg = true;
    this.error_message = environment.APP_MESSAGE_NO_ID;
    this.error = renderError('PAGE_NOT_FOUND');
    this.error_message = this.error?.error_message;
  }
  }


  title = 'default';
  clientId: string = environment.APP_CLIENTID
}
