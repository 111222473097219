import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common'; 
import { AppComponent } from "./app.component";
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from "./app.routing.module";
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import { FormsModule } from "@angular/forms";
import { WelcomeComponent } from "./welcome/welcome.component";
import { OtpComponent } from "./otp/otp.component";
import { ThankyouComponent } from "./thankyou/thankyou.component";
import { CompletedComponent } from "./completed/completed.component";


@NgModule({
    declarations: [AppComponent, HomeComponent,WelcomeComponent,OtpComponent,ThankyouComponent,CompletedComponent,PagenotfoundComponent],
    imports: [BrowserModule,HttpClientModule,CommonModule,FormsModule,AppRoutingModule],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule{}