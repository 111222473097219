<div class="container-fluid">
        <div class="container">
            <div class="page-header pt-4 pb-4" id="banner">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <span class="logo"></span>
                    </div>
                </div>
            </div>

          
                <div *ngIf="showErrorMsg" class="alert alert-warning mt-3" role="alert">
                    <h4 class="alert-heading">{{error?.error_type}}</h4>
                    <p>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" viewBox="0 0 16 16">
                        <path
                          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                      {{error_message}}
                    </p>
                    <p>{{error?.error_details}}</p>
                </div>
        
            <div *ngIf="showIEErrorMsg">
                <div class="row  on-error page-active on-IE">
                    <div class="col-lg-12"> &nbsp;<br>
                        <div class="alert alert-danger col-sm-12 col-md-8" role="alert">
                            Your web browser is out of date. <br> Update your browser for more security, speed and the best
                            experience on this site. <br>
                        </div>
                    </div>
                </div>
                <div class="row  page-active on-IE">
                    <div class="col-sm-12 col-md-12">
        
                        <p>Your web browser (Internet Explorer) is out of date.</p>
                        <p>Please update to one of these current browsers:</p>
                        <div class="row mb-5">
        
                            <div class="col-sm-6 col-lg-4 mt-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="fab fa-edge fa-5x mt-3 mb-2 "></i>
                                        <div class="card-body">
                                            <h5 class="card-title">Microsoft Edge</h5>
                                            <p class="card-text">From Microsoft</p>
                                            <a href="https://www.microsoft.com/edge" class="btn btn-outline-primary">Get
                                                Edge</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4 mt-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="fab fa-chrome fa-5x mt-3 mb-2 "></i>
                                        <div class="card-body">
                                            <h5 class="card-title">Chrome</h5>
                                            <p class="card-text">From Google</p>
                                            <a href="https://www.google.com/chrome/browser/desktop/"
                                                class="btn btn-outline-primary">Get Chrome</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4 mt-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="fab fa-firefox fa-5x mt-3 mb-2 "></i>
                                        <div class="card-body">
                                            <h5 class="card-title">Firefox</h5>
                                            <p class="card-text">From Mozilla Foundation</p>
                                            <a href="https://www.mozilla.org/firefox/new" class="btn btn-outline-primary">Get
                                                Firefox</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>These links provided will direct you to each vendor's official website.</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!hardstop">
                <router-outlet></router-outlet>
            </div>
        </div>
</div>