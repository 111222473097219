import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormService } from '../service/form.service';
import { HomeComponent } from '../home/home.component';
import { Router } from '@angular/router';
import { getuserId, renderError } from '../helpers/utils';
import { NgForm } from '@angular/forms';
import { gEvent, reporting } from '../helpers/reporting';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.css'
})
export class OtpComponent  implements OnInit, AfterViewInit {
  applicationData!: any;
  OTP: any;
  isOTPVerified: boolean = false;
  isNewPINRequested: boolean = false;
  isNewPINSent: boolean = false;
  error_message!: string;
  error: any;
  showError: boolean = false;
  disableVerifyBtn: boolean = false;
  disableReqNewPINBtn: boolean = false;

  @ViewChild('messageSent', {static: false}) messageSent!: ElementRef;
  @ViewChild('heading', {static: false}) heading!: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef,  private homeComp: HomeComponent, private formService: FormService, private router: Router) {
    this.formService.getAppData.subscribe(data => this.applicationData = data);
  }
  ngAfterViewInit(): void {
    this.heading.nativeElement.focus();
  }
  ngOnInit(): void {
  
    if(Object.keys(this.applicationData).length === 0) {
      let formId = getuserId();
      this.formService.getFormData(formId).subscribe(data => {
        this.applicationData = data;
        this.formService.setAppData(data);
      },error=>{
        this.showError = true;
        console.log('error', error);
        this.error = renderError(error?.code);
        this.error_message = this.error?.error_message;
      });
    }
    reporting();
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
  }

  verifyOtp(frm: NgForm) {
    if (frm.invalid) {
      Object.values(frm.controls).forEach(control => {
        control.markAsTouched();
      })
    } else {
      this.isOTPVerified = true;
      this.disableVerifyBtn = true;
      gEvent("OTP verification success", "Verification", "OTP verification label");
      this.formService.verifyOTP(this.applicationData.formId, this.OTP).subscribe(data => {
      this.router.navigate(['/thankyou']);
      },error=>{
        gEvent("OTP fields failed", "Verification", "OTP fields failed label");
        this.disableVerifyBtn = false;
        this.isOTPVerified = false;
        this.showError = true;
        this.error = renderError(error?.code);
        this.error_message = this.error?.error_message;
      });
    }
  }

  requestNewPIN() {
    this.isNewPINRequested = true;
    this.disableReqNewPINBtn = true;
    this.formService.requestOTP(this.applicationData.formId).subscribe(data=>{
      this.isNewPINSent = true;
      this.changeDetectorRef.detectChanges();
      this.isNewPINRequested = false;
      this.disableReqNewPINBtn = false;
      this.messageSent.nativeElement.focus();
      gEvent("OTP requested success", "Verification", "OTP requested successfully");
    },error=>{
      gEvent("OTP request failed", "Verification", "OTP request failed");
      this.isNewPINRequested = false;
      this.disableReqNewPINBtn = false;
      this.showError = true;
      this.error = renderError(error?.code);
      this.error_message = this.error?.error_message;
    })

  }
}
