<div class="flex-shrink-0">
    <div class="container">
        <div class="page-header mt-4 mb-4" id="banner">
            <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-6">
                    <div class="logo barebone-header"><img src="../assets/logo.svg" alt="Queensland Urban Utilities Logo"></div>
                </div>
            </div>
        </div>

    <div class="col-lg-12"> &nbsp;<br>
        <div class="alert alert-danger col-sm-12 col-md-8" role="alert" >
            {{error_message}}
        </div>
    </div>
    </div>
</div>
