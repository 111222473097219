
<div *ngIf="formData">
   <div class="row">
            <div class="col-lg-12">
                <h1 tabindex="0" #mainHeading role="heading" aria-level="1" class="mb-3 mt-3">Hi {{formData.or_first_name}},</h1>
                <div tabindex="0" class="alert alert-secondary">Please confirm that you’re opting
                    out of Digital Statements.
                </div>
            </div>
    </div>
    <div class="row">
            <div class="col-sm-12">
                <div tabindex="0">
                <p *ngIf="formData.or_joint_account == 'Y'"><b>IMPORTANT: If you’re a joint account holder for the account(s) listed in the notification, opting out means all account holders will remain on paper statements.</b></p>
                <p *ngIf="formData.or_otp_channel =='EMAIL'">To continue, we’ll send you a One Time PIN via email to your registered email address consisting of
                    <b>{{formData.or_otp_redacted}}</b>.
                </p>
                <p *ngIf="formData.or_otp_channel =='EMAIL'">Enter the PIN you have received via email on the following screen.</p>
             
                
                <p *ngIf="formData.or_otp_channel =='SMS'">To continue, we’ll send you a One Time PIN via SMS to your registered mobile ending in
                    <b class="text-nowrap">XXXX XXX {{formData.or_otp_redacted}}</b>.
                </p>
                <p *ngIf="formData.or_otp_channel =='SMS'">Enter the PIN you have received via SMS on the following screen.</p>
                
            </div>
                <p>
                    <button aria-label="Continue to enter OTP page" (click) = "requestOTP()" class="btn btn-primary" type="button" [disabled]= "disableContinueBtn" id="request-code">
                        <span [ngClass]="OTPIsSending ? 'spinner-border spinner-border-sm me-1' : 'spinner-border spinner-border-sm d-none'"  role="status" aria-hidden="true"></span>
                        <span>Continue</span>
                    </button>
                </p>
            </div>
    </div>
</div>
    <div class="row" *ngIf="showError">
        <div class="col-lg-12"> &nbsp;<br>
            <div class="alert alert-danger col-sm-12 col-md-8" role="alert" [innerHTML]="error_message"></div>
        </div>
    </div> 

