
<div class="row">
        <div class="col-sm-12">
            <h1 tabindex="0" #heading class="mb-3 mt-3">Message sent</h1>
            <p *ngIf="applicationData.or_otp_channel == 'EMAIL'">A One Time PIN has been sent to your email consisting of <b>{{applicationData.or_otp_redacted}}</b>.
            </p>
            <p *ngIf="applicationData.or_otp_channel == 'SMS'">A One Time PIN has been sent to your mobile ending in <b class="text-nowrap">XXXX XXX
                    {{applicationData.or_otp_redacted}}</b>.
            </p>
            <p>Please enter this PIN in the field below.</p>
            <form #authform="ngForm"  name="authform"  action="#"  (ngSubmit)="verifyOtp(authform)" class="watch-me" id="authform" autocomplete="off" novalidate>
                <div class="form-group col-md-6 col-lg-3">
                    <label for="otp" id="otpcodelabel">Enter your One Time PIN </label>
                    <input id="otp" ngControl="otp" placeholder="" name="otp" type="text" class="form-control" aria-describedby="otpcodelabel"  [(ngModel)]="OTP" #otp="ngModel" required [class.is-invalid]="otp.invalid && (otp.dirty || otp.touched)">
                    <div
                    *ngIf="authform.submitted || otp.invalid && (otp.dirty || otp.touched)"
                    >
                    <div class="invalid-feedback d-block" *ngIf="otp.errors?.['required']">This field is required</div> 
                    </div>
                </div>

                <div class="form-group pt-3">
                    <div *ngIf="showError" class="alert alert-warning mt-3" role="alert" id="error-warning">
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" viewBox="0 0 16 16">
                            <path
                              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                          </svg>
                          {{error_message}}
                        </p>
                      </div>
                    <label class="pb-1">Please verify your PIN in order to continue.</label><br>

                    <button class="btn btn-primary" type="submit" id="verify-code" [disabled]="disableVerifyBtn">
                        <span [ngClass]="isOTPVerified ? 'spinner-border spinner-border-sm me-1' : 'spinner-border spinner-border-sm d-none'"  role="status" aria-hidden="true"></span>
                        <span>Verify PIN</span>
                    </button>


                </div>

                <div class="form-group pt-3">
                    <div>
                        <p>Didn't receive a PIN? PIN expired?<br>
                            Use the Request new PIN button to have one resent.
                        </p>
                        <p>
                            <button class="btn btn-outline-primary" type="button" id="request-new-otp" (click)="requestNewPIN()" [disabled]="disableReqNewPINBtn">
                                <span [ngClass]="isNewPINRequested ? 'spinner-border spinner-border-sm me-1' : 'spinner-border spinner-border-sm d-none'" role="status"
                                    aria-hidden="true"></span>
                                <span>Request new PIN</span>
                            </button>
                        </p>
                        <span tabindex="0" *ngIf="isNewPINSent" #messageSent id="request-new-code-sent">A new PIN has been
                            sent.</span>
                            
                        <p>Your PIN will expire in 10 mins from issue.
                        </p>
                        <p *ngIf="applicationData.or_otp_channel =='EMAIL'">If you don’t receive your PIN email, please check your SPAM folder.</p>
                       
                        <span id="request-new-code-failed" class="collapse fade">An error occurred. Try
                            the link you initially received or try again at a later time.</span>
                    </div>
                </div>
            </form>
        </div>
</div>
