
<div class="row">
        <div class="col-sm-12">
            <h1 tabindex="0" #completedHeading class="mb-3 mt-3">Preference already saved</h1>
            <div id="error_target"></div>
            <div tabindex="0" class="alert alert-secondary">
                Thank you, your preference has already been saved.
            </div>
            <p tabindex="0">If you have any questions about this process, please contact Customer Service on <a
                    href="tel:{{contactNumber}}">{{contactNumber}}</a>
              {{contactTimes}}
            </p>
        </div>
</div>
