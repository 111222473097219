import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { FormService } from '../service/form.service';
import { HomeComponent } from '../home/home.component';
import { getuserId, renderError } from '../helpers/utils';
import { gEvent, reporting } from '../helpers/reporting';


@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrl: './thankyou.component.css'
})
export class ThankyouComponent implements OnInit, AfterViewInit {
  contactTimes: string;
  contactNumber: string;
  applicationData: any;
  showError: boolean = false;
  error_message!: string;
  error: any;
  
  @ViewChild('thankYouHeading', {static: true}) thankYouHeading!: ElementRef;

  constructor(private homeComp: HomeComponent, private formService: FormService) {
    this.formService.getAppData.subscribe(data => this.applicationData = data);
    this.contactTimes = environment.POSTHTML_CONTACT_TIMES;
    this.contactNumber = environment.POSTHTML_CONTACT_NUMBER;
  }
  ngAfterViewInit(): void {
   this.thankYouHeading.nativeElement.focus();
  }
  ngOnInit(): void {
   
    reporting();
    if(Object.keys(this.applicationData).length === 0) {
      let formId = getuserId();
      this.formService.getFormData(formId).subscribe(data => {
        this.applicationData = data;
        this.formService.setAppData(data);
      },error=>{
        this.showError = true;
        console.log('error', error);
        this.error = renderError(error?.code);
        this.error_message = this.error?.error_message;
      });
    }
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
    let data = {
      client: environment.APP_CLIENTID,
      srw_comm_pref: 'optout',
      finalFormSubmission: true
    }
    this.formService.saveFormData(this.applicationData.formId, data).subscribe(data=>{
      gEvent("Form submit success", "Form submit", "Form submit success label")
      this.formService.endSession(this.applicationData.formId).subscribe(res=>{
        console.log("end session called");
        gEvent("Session ended by user", "Session management", "Session ended success label")
      },error=>{
        gEvent("Form submit failed", "Form submit", "Form submit failed label")
        this.showError = true;
        this.error = renderError(error?.code);
        this.error_message = this.error?.error_message;
      });
    },error=>{
      gEvent("Session wipe failed", "Session management", "Session wipe failed label");
      this.showError = true;
      this.error = renderError(error?.code);
      this.error_message = this.error?.error_message;
    })
  }
}
