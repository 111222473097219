import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HomeComponent } from '../home/home.component';
import { environment } from '../../environments/environment';
import { gEvent } from '../helpers/reporting';


@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrl: './completed.component.css'
})
export class CompletedComponent implements OnInit, AfterViewInit {
  contactTimes: string;
  contactNumber: string;
  @ViewChild('completedHeading', {static: true}) completedHeading!: ElementRef;
  constructor(private homeComp: HomeComponent) {
    this.contactTimes = environment.POSTHTML_CONTACT_TIMES;
    this.contactNumber = environment.POSTHTML_CONTACT_NUMBER;
  }
  ngAfterViewInit(): void {
    this.completedHeading.nativeElement.focus();
  }
  ngOnInit(): void {
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
    gEvent("Completed item reattempted by user", "Reattempt", "Reattempt label")
  }
}

