export const environment = {
    production: false,
    brand:'bsa',
    APP_CLIENTID: 'bsabfds',
    APP_TEMPLATEID: '',
    APP_ORIGINATINGAPP: 'BSAB',
    APP_COOKIE_EXPIRY_MINUTES: 60,
    APP_OTP_EXPIRY_MINUTES:  3,
    POSTHTML_DOMAIN: 'test.statements.optout.banksa.com.au',
    POSTHTML_TITLE: 'Bank SA | Digital Statement Opt Out',
    POSTHTML_TITLE_SEPERATOR    : '-',
    POSTHTML_DESCRIPTION: 'Digital Statements',
    APP_GOOGLEID:'',
    GOOGLEUA: '',
    POSTHTML_CONTACT_TIMES: '7:30am to 7:30pm, Monday to Saturday.',
    POSTHTML_CONTACT_NUMBER: '(02) 9155 7850',
    APP_MESSAGE_BAD_REQUEST    : 'Please retry the link you initially received.',
    APP_MESSAGE_FORM_EXPIRED   : 'This item has expired or is no longer available.',
    APP_MESSAGE_NOT_FOUND      : 'Please retry the link you initially received.',
    APP_MESSAGE_FAILED         : 'Your PIN is not valid, ensure to use the most recent PIN to continue.',
    APP_MESSAGE_FORM_UNAUTHORIZED: 'The PIN you have entered is incorrect. Please re-enter the PIN most recently sent to you.',
    APP_MESSAGE_EXPIRED_CODE   : 'Your PIN has expired. Please use the Request new PIN button to be sent a new PIN. Ensure to use only the most recent PIN.',
    APP_MESSAGE_FORM_LOCKUP    : 'You have exceeded all attempts for today. Your access has been temporarily disabled. Please retry the link you initially received in 24hrs.',
    APP_MESSAGE_FORBIDDEN      : 'Please retry the link you initially received.',
    APP_MESSAGE_NO_ID          : 'Please retry the link you initially received without modifcation.',
    APP_MESSAGE_COOKIE_EXPIRED : 'Your session has timed out. Please retry the link you initially received without modifcation.',
    APP_MESSAGE_DEFAULT        : 'Please retry the link you initially received at a later time.'
};