import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";


import { HomeComponent } from "./home/home.component";
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import { WelcomeComponent } from "./welcome/welcome.component";
import { OtpComponent } from "./otp/otp.component";
import { ThankyouComponent } from "./thankyou/thankyou.component";
import { CompletedComponent } from "./completed/completed.component";



const routes: Routes = [
    {
        path: '', component: HomeComponent,
        children: 
        [   
            {
                path:'completed', component: CompletedComponent
            },
            {
                path: 'thankyou', component: ThankyouComponent
            },
            {
                path: 'otp', component: OtpComponent 
            },
            {
                path: ':id', component: WelcomeComponent 
            },
            
        ]
    },
    //Wild Card Route for 404 request 
    { 
         path: '**', pathMatch: 'full', component: PagenotfoundComponent 
    },         
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    
}